import $ from 'jquery';
import 'slick-carousel';
import checkResponsive from '../components/responsiveSwitch';

class TemplateInductees {
	constructor() {
		this.component = document.querySelector('#inductees');
		this.init();
	}

	init() {
		const inducteeGridArray = Array.from(
			this.component.querySelectorAll('.inductee-grid-item')
		);

		this.registerClickEvent(inducteeGridArray);
		this.registerDropdown();
		this.registerSlider();
		window.addEventListener('resize', () => this.registerSlider());
	}

	registerClickEvent(array) {
		array.forEach((inductee, index) => {
			inductee.addEventListener('click', () => {
				array.forEach((inductee) => {
					inductee.classList.remove('active');
				});
				inductee.classList.add('active');
				this.registerContent(index);
			});
		});
	}

	registerContent(index) {
		const component = document.querySelector('#inductees');

		const inducteeContentArray = Array.from(
			component.querySelectorAll('.inductee-content-item')
		);

		inducteeContentArray.forEach((inducteeContent) => {
			inducteeContent.classList.remove('active');
		});
		inducteeContentArray[index].classList.add('active');
	}

	registerDropdown() {
		const registerContent = this.registerContent;
		const customSelects = Array.from(
			document.getElementsByClassName('inductees__inductees--select')
		);
		customSelects.forEach((selectContainer) => {
			const selectElement = selectContainer.querySelector('select');
			const selectedDiv = document.createElement('DIV');
			selectedDiv.setAttribute('class', 'select-selected');
			selectedDiv.innerHTML =
				selectElement.options[selectElement.selectedIndex].innerHTML;
			selectContainer.appendChild(selectedDiv);

			const itemsDiv = document.createElement('DIV');
			itemsDiv.setAttribute('class', 'select-items select-hide');

			for (let j = 1; j < selectElement.length; j++) {
				const optionDiv = document.createElement('DIV');
				optionDiv.innerHTML = selectElement.options[j].innerHTML;
				optionDiv.dataset.value = j;

				optionDiv.addEventListener('click', function (e) {
					const select = this.parentNode.parentNode.querySelector('select');
					const previousSelected = this.parentNode.previousSibling;
					const slider = document.querySelector('.slick-slider');

					if (slider) {
						$(slider).slick('slickGoTo', this.dataset.value);
					}

					for (let i = 0; i < select.length; i++) {
						if (select.options[i].innerHTML === this.innerHTML) {
							select.selectedIndex = i;
							previousSelected.innerHTML = this.innerHTML;

							const sameSelectedItems =
								this.parentNode.querySelectorAll('.active-selected');
							sameSelectedItems.forEach((item) =>
								item.classList.remove('active-selected')
							);
							this.classList.add('active-selected');
							break;
						}
					}
					previousSelected.click();
				});

				itemsDiv.appendChild(optionDiv);
			}

			selectContainer.appendChild(itemsDiv);

			selectedDiv.addEventListener('click', function (e) {
				e.stopPropagation();
				closeAllSelect(this);
				this.nextSibling.classList.toggle('select-hide');
				this.classList.toggle('select-arrow-active');
			});
		});

		function closeAllSelect(element) {
			const items = Array.from(document.getElementsByClassName('select-items'));
			const selected = Array.from(
				document.getElementsByClassName('select-selected')
			);

			selected.forEach((select, index) => {
				if (element !== select) {
					select.classList.remove('select-arrow-active');
				}
			});

			items.forEach((item, index) => {
				if (!selected.includes(element)) {
					item.classList.add('select-hide');
				}
			});
		}

		document.addEventListener('click', closeAllSelect);
	}

	registerSlider() {
		if (checkResponsive()) {
			this.initSlider();
		} else {
			this.destroySlider();
		}
	}

	initSlider() {
		$('.inductees__inductees--content').not('.slick-initialized').slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
		});
	}

	destroySlider() {
		if (this.component.querySelector('.slick-initialized')) {
			$('.inductees__inductees--content').slick('unslick');
		}
	}
}

export default TemplateInductees;
